@import "@ruter-ds/rds-tokens/dist/scss/colors.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  background-color: $color-neutral-96;
}
