$color-ruter: #e60000;
$menu-color-ruter: #ffffff;

$color-brakar: #f3d03e;
$menu-color-brakar: #5e514d;

$color-okt: #ec1b26;
$menu-color-okt: #ffffff;

$color-kolombus: #333f48;
$menu-color-kolombus: #ffffff;

$color-akt: #ffffff;
$menu-color-akt: #000000;

$color-troms: #02a1e4;
$menu-color-troms: #282d34;

$color-default: $color-ruter;
$menu-color-default: $menu-color-ruter;

.header {
  background-color: $color-default;
  color: $menu-color-default;
  &--ruter {
    background-color: $color-ruter;
    color: $menu-color-default;
  }
  &--brakar {
    background-color: $color-brakar;
    color: $menu-color-brakar;
  }
  &--okt {
    background-color: $color-okt;
    color: $menu-color-okt;
  }
  &--kolombus {
    background-color: $color-kolombus;
    color: $menu-color-kolombus;
  }
  &--akt {
    background-color: $color-akt;
    color: $menu-color-akt;
  }
  &--troms {
    background-color: $color-troms;
    color: $menu-color-troms;
  }
  &__logo {
    height: 1.5rem;
  }
}
