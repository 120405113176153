.button-container {
  display: flex;
  justify-content: center;
  margin: 3rem;
  &__content {
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    width: fit-content;
  }
}

@media only screen and (max-width: 600px) {
  .button-container {
    &__content {
      display: grid;
      row-gap: 1rem;
      grid-auto-flow: row;
      justify-items: center;
    }
  }
}
